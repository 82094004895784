import React, { FC } from 'react';
import { graphql, Link, PageProps } from 'gatsby';
import Img from 'gatsby-image';
import { Layout } from '../components/Layout/Layout';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

import { SEO } from '../components/SEO/SEO';

import '../styles/blogpost-template.scss';
import { ShareButtons } from '../components/ShareButtons/ShareButtons';

type PageContextBlogPostTemplate = {
  id: string;
  next: GatsbyTypes.Maybe<GatsbyTypes.ContentfulBlogPost>;
  previous: GatsbyTypes.Maybe<GatsbyTypes.ContentfulBlogPost>;
};

const BlogpostTemplate: FC<
  PageProps<GatsbyTypes.BlogPostTemplateQuery, PageContextBlogPostTemplate>
> = ({ data, pageContext, location }) => (
  <Layout>
    <SEO
      pagetitle={data?.contentfulBlogPost?.title}
      pagedesc={
        data?.contentfulBlogPost?.contentMarkdown?.childMarkdownRemark?.excerpt
      }
      pagepath={location.pathname}
      blogimg={`https:${data?.contentfulBlogPost?.eyecatch?.file?.url}`}
      pageimgw={data?.contentfulBlogPost?.eyecatch?.file?.details?.image?.width}
      pageimgh={
        data?.contentfulBlogPost?.eyecatch?.file?.details?.image?.height
      }
    />
    <div className="blogpost-template">
      <div className="blogpost-template__eyecatch">
        <figure>
          {data?.contentfulBlogPost?.eyecatch?.fluid ? (
            <Img
              fluid={data.contentfulBlogPost.eyecatch.fluid}
              alt={data.contentfulBlogPost.eyecatch.description}
              style={{ height: 300 }}
            />
          ) : null}
        </figure>
      </div>
      <article className="blogpost-template__post">
        <div className="container">
          <aside className="blogpost-template__header">
            <div>
              <time
                className="blogpost-template__date"
                dateTime={data?.contentfulBlogPost?.publishDate}
              >
                DATE {data?.contentfulBlogPost?.publishDateJP}
              </time>
              <div className="blogpost-template__category">
                <div>
                  <span>CATEGORY</span>
                </div>
                <ul className="blogpost-template__category-ul">
                  {data?.contentfulBlogPost?.category?.map((cat) => (
                    <li
                      className="blogpost-template__category-li"
                      key={cat?.id}
                    >
                      <Link
                        className="blogpost-template__category-link"
                        to={`/cat/${cat?.categorySlug}/`}
                      >
                        {cat?.category}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <h1 className="blogpost-template__title">
              {data?.contentfulBlogPost?.title}
            </h1>
            <div>
              <ShareButtons
                url={`https://shimu.dev/blog/post/${data?.contentfulBlogPost?.slug}`}
                title={data?.contentfulBlogPost?.title ?? ''}
                excerpt={
                  data?.contentfulBlogPost?.contentMarkdown?.childMarkdownRemark
                    ?.excerpt ?? ''
                }
              />
            </div>
          </aside>
          <div
            className="blogpost-template__body"
            dangerouslySetInnerHTML={{
              __html:
                data?.contentfulBlogPost?.contentMarkdown?.childMarkdownRemark
                  ?.html ?? '',
            }}
          />
          <ul className="blogpost-template__postlink">
            {pageContext.next && (
              <li>
                <Link
                  className="blogpost-template__postlink-link"
                  to={`/blog/post/${pageContext.next.slug}/`}
                  rel="prev"
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                  <span>{pageContext.next.title}</span>
                </Link>
              </li>
            )}
            {pageContext.previous && (
              <li>
                <Link
                  className="blogpost-template__postlink-link"
                  to={`/blog/post/${pageContext.previous.slug}/`}
                  rel="next"
                >
                  <span>{pageContext.previous.title}</span>
                  <FontAwesomeIcon icon={faChevronRight} />
                </Link>
              </li>
            )}
          </ul>
        </div>
      </article>
    </div>
  </Layout>
);

export const query = graphql`
  query BlogPostTemplate($id: String!) {
    contentfulBlogPost(id: { eq: $id }) {
      title
      slug
      publishDateJP: publishDate(formatString: "YYYY年MM月DD日")
      publishDate
      category {
        category
        categorySlug
        id
      }
      eyecatch {
        fluid(maxWidth: 1600) {
          ...GatsbyContentfulFluid_withWebp
        }
        description
        file {
          details {
            image {
              width
              height
            }
          }
          url
        }
      }
      contentMarkdown {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 70)
        }
      }
    }
  }
`;

export default BlogpostTemplate;
