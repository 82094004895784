import React, { FC } from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LineShareButton,
  LineIcon,
} from 'react-share';

import './shareButtons.scss';
type Prop = {
  url: string;
  title: string;
  excerpt: string;
};
export const ShareButtons: FC<Prop> = ({ url, title, excerpt }) => {
  const iconSize = 50;
  return (
    <div className="share-buttons">
      <div className="share-buttons__button">
        <TwitterShareButton url={url} title={title}>
          <TwitterIcon round size={iconSize} />
        </TwitterShareButton>
      </div>
      <div className="share-buttons__button">
        <LineShareButton url={url} title={title}>
          <LineIcon round size={iconSize} />
        </LineShareButton>
      </div>
      <div className="share-buttons__button">
        <FacebookShareButton url={url} quote={excerpt}>
          <FacebookIcon round size={iconSize} />
        </FacebookShareButton>
      </div>
    </div>
  );
};
